import { Container, Dropdown, Form, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Key, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { SelectedVehicleContext } from '../../../contexts/SelectedVehicleContext.tsx';
import { getFlagName, getToken, removeToken } from '../../../utils.ts';
import { Territory } from '../../../interfaces/Territory.ts';

interface Props {
  territories: Territory[];
}
export function Header(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const territorySlug = useParams() as { territorySlug: string };
  const [localTime, setLocalTime] = useState(new Date().toLocaleTimeString());
  const { setSelectedVehicle } = useContext(SelectedVehicleContext);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setValue(territorySlug.territorySlug);
  }, [territorySlug]);

  useEffect(() => {
    setInterval(() => setLocalTime(new Date().toLocaleTimeString()), 1000);
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const updateValue = ({ target }) => {
    setSelectedVehicle(undefined);
    navigate(`/dashboard/${target.value}/`);
    window.location.reload();
  };
  const redirectDashboard = (territorySlug: string) => {
    navigate(`/dashboard/${territorySlug}/`);
  };
  const redirectMissionHistory = (territorySlug: string) => {
    navigate(`/mission-history/${territorySlug}/`);
  };

  function handleDisconnect() {
    if (getToken()) {
      removeToken();
    }
    navigate('/login/');
  }

  return (
    <Navbar className={'header justify-content-between shadow-sm'}>
      <Container fluid className={'ms-3 me-3'}>
        <Link to={'/'}>
          <img
            src={'/theme/media/logos/logo-default.svg'}
            height="50"
            className="rounded-2 mt-2"
            alt="Padam Av logo"
          />
        </Link>
        <div className={'d-flex justify-content-between'}>
          <p className={'my-auto fw-bold me-2'}>
            {new Date().toLocaleDateString()} {localTime}
          </p>
          <div className={'me-3'}>
            <Dropdown align={'end'}>
              <Dropdown.Toggle
                id="dropdown-basic"
                className={'bg-light border-0 internationalization-dropdown'}>
                <div className={'d-flex'}>
                  <img
                    src={`/theme/media/flags/${getFlagName(i18n.language)}.png`}
                    height="24"
                    className="rounded-2"
                    alt="Flag language"
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => changeLanguage('en')}>
                  <div className={'d-flex'}>
                    <img
                      src={`/theme/media/flags/en.png`}
                      height="24"
                      className="rounded-2"
                      alt="UK flag"
                    />
                    <p className={'my-auto ms-2'}>English</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage('fr')}>
                  <div className={'d-flex'}>
                    <img
                      src={`/theme/media/flags/fr.png`}
                      height="24"
                      className="rounded-2"
                      alt="FR flag"
                    />
                    <p className={'my-auto ms-2'}>Français</p>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {value === undefined ? (
            <></>
          ) : (
            <Form.Select
              className={'territory-select me-3 rounded-4'}
              onChange={updateValue}
              value={value}
              disabled={
                props.territories?.filter(territory => territory.has_connections_active)?.length < 2
              }>
              {props.territories ? (
                props.territories
                  .filter(territory => territory.has_connections_active)
                  .map((territory: Territory, i: Key) => (
                    <option key={String(i)} value={territory.slug}>
                      {territory.slug}
                    </option>
                  ))
              ) : (
                <></>
              )}
            </Form.Select>
          )}
          <NavDropdown
            title={
              <img
                src={'/theme/media/burger.svg'}
                width="29"
                height="26"
                className="rounded-2"
                alt="Burger menu logo"
              />
            }
            className={'me-2 my-auto'}
            drop={'down'}
            align={'end'}>
            {territorySlug?.territorySlug !== undefined ? (
              <>
                <Dropdown.Item
                  className={'mt-2 mb-2'}
                  onClick={() => redirectDashboard(territorySlug.territorySlug)}>
                  <p>
                    <img
                      src={'/theme/media/monitor.svg'}
                      width="29"
                      height="26"
                      className="rounded-2"
                      alt="Dashboard"
                    />
                    <span className={'text-bold ms-1'}>{t('header.dashboard')}</span>
                  </p>
                </Dropdown.Item>
                <Dropdown.Item
                  className={'mt-2 mb-2'}
                  onClick={() => redirectMissionHistory(territorySlug.territorySlug)}>
                  <p>
                    <img
                      src={'/theme/media/mission.svg'}
                      width="29"
                      height="26"
                      className="rounded-2"
                      alt="Dashboard"
                    />
                    <span className={'text-bold ms-1'}>{t('header.mission-history')}</span>
                  </p>
                </Dropdown.Item>
                <hr className={'mt-1 mb-1 basic-hr'} />
              </>
            ) : (
              <></>
            )}
            <Dropdown.Item className={'mt-2 mb-2'} onClick={() => navigate('/settings/')}>
              <p>
                <img
                  src={'/theme/media/settings-logo.svg'}
                  width="29"
                  height="26"
                  className="rounded-2"
                  alt="Dashboard"
                />
                <span className={'text-bold-grey ms-1'}>{t('header.settings')}</span>
              </p>
            </Dropdown.Item>
            <hr className={'mt-1 mb-1 basic-hr'} />
            <Dropdown.Item className={'mt-2 mb-2'} onClick={handleDisconnect}>
              <p>
                <img
                  src={'/theme/media/ArrowLineRight.svg'}
                  width="29"
                  height="26"
                  className="rounded-2"
                  alt="Logout"
                />
                <span className={'text-bold text-alert ms-1'}>{t('header.logout')}</span>
              </p>
            </Dropdown.Item>
            <hr className={'mt-1 mb-1 basic-hr'} />
            <div className={'ms-3 mt-3 mb-2'}>
              <p className={'text-disabled text-collapse'}>
                Front : {import.meta.env.VITE_FRONTEND_VERSION}
              </p>
              <p className={'text-disabled text-collapse'}>
                Back : {props.territories?.find(() => true)?.backend_version}
              </p>
            </div>
          </NavDropdown>
        </div>
      </Container>
    </Navbar>
  );
}
