import { Place } from '../../../interfaces/Place.ts';
import {
  BOOKING_STATUS_ACTION_DROPOFF,
  BOOKING_STATUS_ACTION_PICKUP,
  PASSENGER_STATUS_CANCELED,
  PASSENGER_STATUS_DROPOFF,
  PASSENGER_STATUS_ONBOARD,
  PLACE_STATUS_IN_PLACE,
  PLACE_STATUS_NOT_VISITED,
  PLACE_STATUS_VISITED,
  PLACE_STATUS_WAITING_FOR_CANCELLED_ACKNOWLEDGEMENT,
  PLACE_STATUS_WAITING_FOR_CONFIRM,
} from '../../../constants.ts';
import { Booking } from '../../../interfaces/Booking.ts';

export function getCurrentPlace(roadmap: [Place]): Place {
  let previousIndex = 0;
  for (let i = 0; i < roadmap.length; i++) {
    if (roadmap[i].status === PLACE_STATUS_IN_PLACE) {
      return roadmap[i];
    } else if (roadmap[i].status === PLACE_STATUS_VISITED) {
      previousIndex = i;
    }
  }
  return roadmap[previousIndex];
}

export function getNextPlace(roadmap: [Place]): Place {
  let nextPlaceIndex = 0;
  const status = [
    PLACE_STATUS_NOT_VISITED,
    PLACE_STATUS_WAITING_FOR_CONFIRM,
    PLACE_STATUS_WAITING_FOR_CANCELLED_ACKNOWLEDGEMENT,
  ];
  for (let i = 0; i < roadmap.length; i++) {
    if (status.includes(roadmap[i].status)) {
      nextPlaceIndex = i;
      break;
    }
  }
  return roadmap[nextPlaceIndex];
}
export function displayInPlaceButton(
  currentPlace: Place,
  nextPlace: Place,
  selectedPlace: Place,
): boolean {
  let displayInPlaceButton = false;
  if (
    (nextPlace.order === selectedPlace.order &&
      [
        PLACE_STATUS_NOT_VISITED,
        PLACE_STATUS_WAITING_FOR_CONFIRM,
        PLACE_STATUS_WAITING_FOR_CANCELLED_ACKNOWLEDGEMENT,
      ].includes(selectedPlace.status) &&
      currentPlace.status === PLACE_STATUS_VISITED) ||
    (selectedPlace.status === PLACE_STATUS_NOT_VISITED && selectedPlace.order === 0)
  ) {
    displayInPlaceButton = true;
  }
  return displayInPlaceButton;
}

export function displayReadyToLeaveButton(currentPlace: Place, selectedPlace: Place): boolean {
  let displayReadyToLeaveButton = false;
  if (currentPlace.order === selectedPlace.order && selectedPlace.status === PLACE_STATUS_IN_PLACE) {
    displayReadyToLeaveButton = true;
  }
  return displayReadyToLeaveButton;
}

export function refreshSelectedPlace(selectedPlace: Place | null, roadmap: Place[]): Place | null {
  if (roadmap !== undefined && selectedPlace !== undefined && selectedPlace?.id !== undefined) {
    return roadmap.find((place: Place) => place.id === selectedPlace.id) || null;
  } else {
    return selectedPlace;
  }
}

export function getBookingsToPickUp(selectedPlace: Place | null): Booking[] | undefined {
  return selectedPlace?.bookings.filter(
    (booking: Booking) => booking.action === BOOKING_STATUS_ACTION_PICKUP,
  );
}

export function isPickUpButtonChecked(booking: Booking): boolean {
  return [PASSENGER_STATUS_ONBOARD, PASSENGER_STATUS_DROPOFF].includes(booking.passenger_status);
}

export function enablePickUpButton(
  selectedPlace: Place | null,
  currentPlace: Place | undefined,
  booking: Booking,
): boolean {
  return (
    selectedPlace?.order === currentPlace?.order &&
    ![PASSENGER_STATUS_ONBOARD, PASSENGER_STATUS_CANCELED].includes(booking.passenger_status)
  );
}

export function enableAbsentButton(
  selectedPlace: Place | null,
  currentPlace: Place | undefined,
  booking: Booking,
): boolean {
  return (
    selectedPlace?.order === currentPlace?.order &&
    ![PASSENGER_STATUS_ONBOARD, PASSENGER_STATUS_CANCELED].includes(booking.passenger_status)
  );
}

export function isDropOffButtonChecked(booking: Booking): boolean {
  return booking.passenger_status === PASSENGER_STATUS_DROPOFF;
}

export function enableDropOffButton(
  selectedPlace: Place | null,
  currentPlace: Place | undefined,
  booking: Booking,
): boolean {
  return (
    selectedPlace?.order === currentPlace?.order &&
    ![PASSENGER_STATUS_DROPOFF, PASSENGER_STATUS_CANCELED].includes(booking.passenger_status)
  );
}

export function getBookingsToDropOff(selectedPlace: Place | null): Booking[] | undefined {
  return selectedPlace?.bookings.filter(
    (booking: Booking) => booking.action === BOOKING_STATUS_ACTION_DROPOFF,
  );
}

export function getStepIndicatorUi(i: number, currentPlace: Place, nextPlace: Place, roadmap: Place[]) {
  let design;
  if (i < currentPlace.order) {
    design = {
      bgcolor: 'white',
      border: '4px solid',
      borderColor: '#4385E8',
    };
  } else if (i === currentPlace.order) {
    if (currentPlace.status === PLACE_STATUS_VISITED) {
      design = {
        bgcolor: 'white',
        border: '4px solid',
        borderColor: '#4CD471',
      };
    } else {
      design = {
        bgcolor: 'white',
        border: '4px solid',
        borderColor: '#4385E8',
      };
    }
  } else if (i === roadmap.length - 1) {
    design = {
      bgcolor: '#CDCFD0',
    };
  } else if (i === nextPlace.order) {
    design = {
      bgcolor: 'white',
      border: '4px solid',
      borderColor: '#A45CFF',
    };
  } else {
    design = {
      bgcolor: 'white',
      border: '4px solid',
      borderColor: '#CDCFD0',
    };
  }
  return design;
}
