import { useQuery } from '@tanstack/react-query';
import { getTerritories, getTerritory } from '../queries/getTerritories.ts';

export const useAllTerritories = () => {
  return useQuery({
    queryKey: ['territories'],
    queryFn: getTerritories,
    retry: false,
  });
};

export const useTerritory = (territorySlug: string) => {
  return useQuery({
    queryKey: ['territory', territorySlug],
    queryFn: () => getTerritory(territorySlug),
    retry: false,
    enabled: territorySlug !== undefined,
  });
};
