import { getToken } from '../utils.ts';

export const websocketUrl: string = import.meta.env.VITE_WEBSOCKET_BASE_URL;
export const domain: string = import.meta.env.VITE_API_BASE_URL;
export const apiPath: string = '/api/v1/';
export let header: object = {
  Authorization: 'Token' + ' ' + getToken(),
};

export const loginUrl: string = domain + apiPath + 'authentication/token/';
export const passwordChangeUrl: string = domain + apiPath + 'authentication/change-password/';
export const passwordResetUrl: string = domain + apiPath + 'authentication/password_reset/';
export const passwordResetChangeUrl: string =
  domain + apiPath + 'authentication/password_reset/confirm/';
export const getTerritoriesUrl: string = domain + apiPath + 'territories/';
export const getTerritoryUrl = (territorySlug: string): string => {
  return domain + apiPath + `territories/${territorySlug}/`;
};

export const getWebsocketTokenUrl: string = domain + apiPath + 'authentication/websocket/token/';
export const getMissionsUrl = (
  territorySlug: string,
  queryParameters: Record<string, string>,
): string => {
  const newParams = new URLSearchParams(queryParameters);
  return domain + apiPath + `territories/${territorySlug}/missions?` + newParams.toString();
};

export const getMissionsDetailUrl = (territorySlug: string, missionId: string): string => {
  return domain + apiPath + `territories/${territorySlug}/missions/${missionId}/`;
};

export const getMissionHistoryUrl = (territorySlug: string, missionId: string): string => {
  return domain + apiPath + `territories/${territorySlug}/missions/${missionId}/history/`;
};
export const patchMissionsUrl = (territorySlug: string, missionId: number): string => {
  return domain + apiPath + `territories/${territorySlug}/missions/${missionId}/`;
};
export const getVehiclesUrl = (territorySlug: string): string => {
  return domain + apiPath + `territories/${territorySlug}/vehicles/`;
};

export const patchVehicleUrl = (territorySlug: string, vehicleId: number): string => {
  return domain + apiPath + `territories/${territorySlug}/vehicles/${vehicleId}/`;
};

export const getShiftUrl = (territorySlug: string, vehicleId: number): string => {
  return domain + apiPath + `territories/${territorySlug}/vehicles/${vehicleId}/current-or-next-shift/`;
};
export const getRoadmapUrl = (territorySlug: string, vehicleId: number): string => {
  return domain + apiPath + `territories/${territorySlug}/vehicles/${vehicleId}/roadmap/`;
};

export const getVehiclesAutomataLogsUrl = (territorySlug: string, vehicleId: number): string => {
  return domain + apiPath + `territories/${territorySlug}/vehicles/${vehicleId}/automata-logs/`;
};
export const getVehiclesLogsUrl = (territorySlug: string, vehicleId: number): string => {
  return domain + apiPath + `territories/${territorySlug}/vehicles/${vehicleId}/logs/`;
};
export const getCreateMissionUrl = (territorySlug: string, vehicleId: number): string => {
  return domain + apiPath + `territories/${territorySlug}/vehicles/${vehicleId}/create-mission/`;
};
export const getGeographyUrl = (territorySlug: string): string => {
  return domain + apiPath + `territories/${territorySlug}/geography/`;
};

export const getConfigurationUrl = (territorySlug: string): string => {
  return domain + apiPath + `territories/${territorySlug}/configuration/`;
};

export function refreshToken() {
  header = { Authorization: 'Token' + ' ' + getToken() };
}

export const getPostActionUrl = (territorySlug: string, vehicleId: number): string => {
  return domain + apiPath + `territories/${territorySlug}/vehicles/${vehicleId}/do-action/`;
};
