import { Shift } from './Shift/Shift.tsx';
import { Map } from './Map/Map.tsx';
import { useQuery } from '@tanstack/react-query';
import { getGeography } from '../../queries/getGeography.ts';
import { Kpi } from './Kpi/Kpi.tsx';
import { Roadmap } from './Roadmap/Roadmap.tsx';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Mission } from './Mission/Mission.tsx';
import { getWebsocketToken } from '../../queries/getWebsocketToken.ts';
import { getTerritoryConfiguration } from '../../utils.ts';
import { TerritoryConfig } from '../../interfaces/TerritoryConfig.ts';
import { SelectedVehicleContext } from '../../contexts/SelectedVehicleContext.tsx';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { websocketUrl } from '../../queries/constants.ts';
import { processVehicleMessage } from '../../websocket/processing.ts';
import { IVehicle } from '../../interfaces/Vehicle.ts';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useVehiclesShiftByTerritory } from '../../services/Vehicle.ts';
import { useTerritory } from '../../services/Territory.ts';

interface Props {
  territorySlug: string;
}
export function Dashboard(props: Props) {
  const [territoryConfig, setTerritoryConfig] = useState<TerritoryConfig | null>();

  const [vehicles, setVehicles] = useState<IVehicle[] | undefined>([]);
  const navigate = useNavigate();

  const { selectedVehicle } = useContext(SelectedVehicleContext);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(websocketUrl, {
    share: false,
    shouldReconnect: () => true,
  });

  const { data: geography, error: geographyError } = useQuery({
    queryKey: ['geography', props.territorySlug],
    queryFn: () => getGeography(props.territorySlug),
  });

  const { data: websocketToken } = useQuery({
    queryKey: ['websocketToken'],
    queryFn: () => getWebsocketToken(),
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 10,
  });

  const { data: territory } = useTerritory(props.territorySlug);

  const vehiclesData = useVehiclesShiftByTerritory(props.territorySlug);

  useEffect(() => {
    const vehiclesNames = vehicles?.map((vehicle: IVehicle) => vehicle.provider_identifier);
    if (
      readyState === ReadyState.OPEN &&
      websocketToken &&
      vehiclesNames !== undefined &&
      vehiclesNames.length > 0
    ) {
      sendJsonMessage({
        type: 'init',
        auth_token: websocketToken?.data.WEBSOCKET_TOKEN,
        object_type: 'vehicle_states',
        object_id_list: vehiclesNames,
      });
    }
  }, [readyState, websocketToken, vehicles, vehiclesData]);

  useEffect(() => {
    if (lastJsonMessage?.message_type === 'vehicle_states' && vehicles !== undefined) {
      processVehicleMessage(lastJsonMessage, vehicles);
      setVehicles(vehicles);
    } else {
      setVehicles(vehiclesData?.data);
    }
  }, [lastJsonMessage, vehiclesData?.data]);

  useEffect(() => {
    if (vehiclesData?.data) {
      getTerritoryConfiguration(props.territorySlug).then(res => {
        setTerritoryConfig(res);
      });
    }
  }, [props, vehiclesData?.data]);

  useEffect(() => {
    const error = geographyError as AxiosError;
    if (
      error !== null &&
      error !== undefined &&
      error.response?.status &&
      error.response?.status >= 500
    ) {
      navigate('/server-error/');
    }
  }, [geographyError]);

  return (
    <Container className={'dashboard-container'}>
      <Row>
        <Col sm={3} className={'pe-0 dashboard-col'}>
          <Shift
            websocketToken={websocketToken?.data}
            territorySlug={props.territorySlug}
            geography={geography?.data}
            territoryConfig={territoryConfig}
            vehiclesList={vehicles}
            territoryObj={territory?.data}
          />
        </Col>
        <Col sm={6} className={'dashboard-col'}>
          <div>
            {selectedVehicle?.id === undefined ? (
              <Kpi />
            ) : (
              <>
                <Roadmap territorySlug={props.territorySlug} territoryObj={territory?.data} />
              </>
            )}
          </div>
          <Map vehicles={vehicles} territorySlug={props.territorySlug} geography={geography?.data} />
        </Col>
        <Col sm={3} className={'ps-0 dashboard-col'}>
          <Mission
            websocketToken={websocketToken?.data}
            territorySlug={props.territorySlug}
            territoryConfig={territoryConfig}
          />
        </Col>
      </Row>
    </Container>
  );
}
