import { useContext, useEffect, useRef, useState } from 'react';
import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import { stepClasses, StepIndicator } from '@mui/joy';
import { FormatTime } from '../../../utils.ts';
import { SelectedVehicleContext } from '../../../contexts/SelectedVehicleContext.tsx';
import { useTranslation } from 'react-i18next';
import { Place } from '../../../interfaces/Place.ts';
import { Spinner } from 'react-bootstrap';
import { PLACE_ACTIVITY_PARKING } from '../../../constants.ts';
import { getCurrentPlace, getNextPlace, getStepIndicatorUi, refreshSelectedPlace } from './utils.ts';
import { ShiftActions } from './ShiftActions.tsx';
import { Territory } from '../../../interfaces/Territory.ts';
import { RoadmapActions } from './RoadmapActions.tsx';
import { useRoadmap } from '../../../services/Roadmap.ts';
import { useShiftByVehicle } from '../../../services/Vehicle.ts';

interface Props {
  territorySlug: string;
  territoryObj: Territory | undefined;
}
export function Roadmap(props: Props) {
  const { selectedVehicle } = useContext(SelectedVehicleContext);
  const ref = useRef<null | HTMLDivElement>(null);
  const { t } = useTranslation();
  const [currentPlace, setCurrentPlace] = useState<undefined | Place>(undefined);
  const [nextPlace, setNextPlace] = useState<undefined | Place>(undefined);
  const [selectedPlace, setSelectedPlace] = useState<null | Place>(null);
  const [showPlaceDetails, setShowPlaceDetails] = useState(false);
  const { data: shiftData } = useShiftByVehicle(props.territorySlug, selectedVehicle?.id || 0);

  const handleClosePlaceDetails = () => setShowPlaceDetails(false);
  function handleShowPlaceDetails(place: Place) {
    setSelectedPlace(place);
    setShowPlaceDetails(true);
  }
  const {
    data: roadmapData,
    isLoading: dataLoading,
    isError: dataError,
  } = useRoadmap(props.territorySlug, selectedVehicle?.id || 0);

  useEffect(() => {
    if (roadmapData !== undefined) {
      setCurrentPlace(getCurrentPlace(roadmapData?.data));
      setNextPlace(getNextPlace(roadmapData?.data));
      setSelectedPlace(refreshSelectedPlace(selectedPlace, roadmapData?.data));
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'instant', block: 'center' });
      }
    }
  }, [selectedVehicle, roadmapData, ref.current, roadmapData?.data]);

  return (
    <div className={'top-center-container row m-0 p-0'}>
      <div className={'col my-auto'}>
        <div className={'row'}>
          {props.territoryObj?.permissions.can_perform_pause_shift ? (
            <ShiftActions territorySlug={props.territorySlug} shift={shiftData?.data[0]} />
          ) : (
            <></>
          )}
          <div className={'col-9 ps-0'}>
            {dataLoading ? (
              <Spinner className={'ms-4 text-center'} animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <></>
            )}
            {dataError ? <div className={'text-basic ms-4'}>{t('roadmap.no-roadmap')}</div> : <></>}
            <Stepper
              sx={{
                '--Step-connectorThickness': '12px',
                '--Step-connectorInset': '-2px',
                '--Step-connectorRadius': '0px',
                '--Step-gap': '0px',
                '--StepIndicator-size': '25px',
                '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
                [`& .${stepClasses.vertical}`]: {
                  minWidth: '175px',
                  '&::after': {
                    background: '#F2F2F2',
                  },
                },
                [`& .${stepClasses.completed}`]: {
                  '&::after': { bgcolor: '#4385E8' },
                },
              }}>
              {roadmapData?.data && currentPlace !== undefined && nextPlace !== undefined ? (
                roadmapData.data.map((place: Place, i: number) => (
                  <Step
                    id={`roadmap-step-${i}`}
                    sx={{
                      '&::after': {
                        top: 'calc(24px) !important',
                      },
                    }}
                    key={i}
                    completed={i < currentPlace?.order}
                    active={i === currentPlace?.order}
                    disabled={i === roadmapData.data.length - 1}
                    orientation="vertical"
                    indicator={
                      <StepIndicator
                        id={`roadmap-indicator-${i}`}
                        variant="solid"
                        onClick={() => handleShowPlaceDetails(place)}
                        sx={getStepIndicatorUi(i, currentPlace, nextPlace, roadmapData.data)}>
                        {place.type === PLACE_ACTIVITY_PARKING ? (
                          <img
                            src={'/theme/media/parking.svg'}
                            width="12"
                            height="12"
                            alt="Clock icon"
                            style={{ marginLeft: '1px' }}
                          />
                        ) : (
                          <></>
                        )}
                      </StepIndicator>
                    }>
                    <p
                      id={`roadmap-place-${i}`}
                      ref={i === currentPlace?.order + 1 ? ref : null}
                      className={'text-basic text-hover'}
                      onClick={() => handleShowPlaceDetails(place)}>
                      {place.name}
                    </p>
                    <p
                      className={'roadmap-departure-time text-basic'}
                      id={`roadmap-departure-time-${i}`}
                      onClick={() => handleShowPlaceDetails(place)}>
                      {FormatTime(place.arrival_time)} - {FormatTime(place.departure_time)}
                    </p>
                  </Step>
                ))
              ) : (
                <></>
              )}
            </Stepper>
          </div>
        </div>
        <RoadmapActions
          handleClosePlaceDetails={handleClosePlaceDetails}
          showPlaceDetails={showPlaceDetails}
          currentPlace={currentPlace}
          selectedPlace={selectedPlace}
          nextPlace={nextPlace}
          territorySlug={props.territorySlug}
          territoryObj={props.territoryObj}
          shift={shiftData?.data[0]}
        />
      </div>
    </div>
  );
}
