import { Accordion } from 'react-bootstrap';
import { Node } from '../Map/Map.tsx';
import { TerritoryConfig } from '../../../interfaces/TerritoryConfig.ts';
import { useTranslation } from 'react-i18next';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { Vehicle } from './Vehicle.tsx';
import { useEffect, useState } from 'react';
import { Territory } from '../../../interfaces/Territory.ts';

export interface WebsocketToken {
  WEBSOCKET_TOKEN: string;
}

interface Props {
  territorySlug: string;
  websocketToken: WebsocketToken | undefined;
  geography: Node[];
  territoryConfig: TerritoryConfig | undefined | null;
  vehiclesList: IVehicle[] | undefined;
  territoryObj: Territory | undefined;
}

export function Shift(props: Props) {
  const { t } = useTranslation();
  const [vehicles, setVehicles] = useState<IVehicle[] | undefined>();
  const [vehiclesConnected, setVehiclesConnected] = useState<undefined | number>();

  useEffect(() => {
    setVehicles(props.vehiclesList?.sort((a: IVehicle, b: IVehicle) => a.id - b.id));
  }, [props.vehiclesList]);

  useEffect(() => {
    setVehiclesConnected(props.vehiclesList?.filter((x: IVehicle) => x?.is_online).length);
  }, [props.vehiclesList]);

  return (
    <div className={'driver-shift-container'}>
      <div className={'row m-0 p-0 pt-3 pb-3'}>
        <div>
          <p className={'card-title ms-2'}>
            <img src={'/theme/media/shift-icon.svg'} width="56" height="42" alt="Journey icon" />
            <span className={'ms-3'}>{t('shift.title')}</span>
          </p>
        </div>
      </div>
      <div className={'ms-3 mt-2'}>
        <p className={'card-subtitle text-center'}>
          {vehiclesConnected !== undefined && vehiclesConnected !== 0 ? (
            <span>
              {vehiclesConnected} / {props.vehiclesList?.length} {t('shift.vehicle-connected')}
            </span>
          ) : (
            <span>{t('shift.vehicle-disconnected')}</span>
          )}
        </p>
      </div>
      <Accordion className={'mb-5 ms-4 me-4'}>
        <div className={'accordion accordion-flush mt-4 text-center'}>
          {vehicles?.map((vehicle: IVehicle, i: number) => (
            <Vehicle
              geography={props.geography}
              vehicle={vehicle}
              territorySlug={props.territorySlug}
              territoryConfig={props.territoryConfig}
              key={i}
              index={i}
              territoryObj={props.territoryObj}
            />
          ))}
        </div>
      </Accordion>
    </div>
  );
}
