import axios from 'axios';
import { getTerritoriesUrl, getTerritoryUrl, header } from './constants.ts';

export async function getTerritories() {
  return await axios.get(getTerritoriesUrl, { headers: header });
}

export async function getTerritory(territorySlug: string) {
  return await axios.get(getTerritoryUrl(territorySlug), { headers: header });
}
